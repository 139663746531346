import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import etMacbook from "../../assets/proj_et-macbook2.png"
import etCollage from "../../assets/et-collage.png"

export default function Enchanting() {
    return (
        <Layout>
            <div className="width-container">
                <div className="single-project-container">
                    <h1>Enchanting Travels</h1>
                    <ul className="project-header">
                        <li>
                            <span className="label">Role</span>
                            <span className="role">Frontend Development</span>
                        </li>
                        <li>
                            <span className="label">Year</span>
                            <span className="role">2016-2018</span>
                        </li>
                        <li>
                            <span className="label">Team</span>
                            <span className="role">Parallel Labs</span>
                        </li>
                    </ul>
                    <div className="single-project-content">
                        <p>
                            Enchanting Travels offers tailor-made travel
                            experiences across 40 countries, which has been
                            operational for more than a decade now. I have had
                            the opportunity to work on many of the different
                            facets starting from customer-facing interface to
                            their internal booking and management interface.
                        </p>
                        <p>
                            Being a part of a tight-knit team of designers and
                            developers, rapid prototyping was a part of the
                            process since day one, undertaking number of
                            iterations based on internal reviews and most
                            importantly through user testing.{" "}
                            <em>“Travelmaker”</em>, their flagship product which
                            helps the Travel Consultants in planning, booking
                            trip itineraries, maintaining financial aspects of
                            the booking and many other tasks like so, has
                            evolved from version 0.5 to 2.0 during the course of
                            time.
                        </p>
                        <figure className="single-project-media">
                            <img
                                src={etMacbook}
                                alt="enchanting travels itinerary"
                            ></img>
                        </figure>
                        <p>
                            We started with simple HTML & CSS prototypes and
                            moved on to build the platform with VueJS. We also
                            used{" "}
                            <a
                                href="https://storybook.js.org"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Storybook
                            </a>{" "}
                            that helps build the UI components while maintaining
                            the design styleguide.
                        </p>
                        <p>
                            Maintaining a consistent design language while
                            working on such a major application becomes a tough
                            feat. The designers of the team created a visual
                            language to overcome this challenge. I undertook the
                            code refactor of the entire application to implement
                            the new visual language to make the whole experience
                            cohesive.
                        </p>
                        <figure>
                            <img
                                src={etCollage}
                                alt="visual language collage"
                            ></img>
                            <figcaption className="image-caption">
                                Image taken from :{" "}
                                <a
                                    href="https://www.parallellabs.io/enchantingtravels"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {" "}
                                    Parallel Labs{" "}
                                </a>{" "}
                            </figcaption>
                        </figure>
                        <div className="learnings">
                            <p>
                                I believe every project no matter how big or
                                small teaches you something you didn’t know
                                before. I had a great learning experience while
                                working on this particular project, I not only
                                improved with leaps and bounds in the quality of
                                code that I used to write but also learnt a
                                great deal about teamwork and better time
                                management.
                            </p>
                            <ul>
                                <li>I also learnt &mdash;</li>
                                <li>
                                    &mdash; how to collaborate with different
                                    teams situated in different places to gain
                                    maximum productivity and efficient results.
                                </li>
                                <li>
                                    &mdash; the importance of communication with
                                    your teammates so that everyone is on the
                                    same page and working towards one common
                                    goal.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <Link to="/work/wow-club" className="read-btn">
                        Next: WoW Club &nbsp; &#187;
                        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path d="M 18.103516 6.9355469 C 17.920891 6.9164219 17.733047 6.94725 17.560547 7.03125 C 17.217547 7.19825 17 7.5476875 17 7.9296875 L 17 11.998047 L 4 11.998047 C 2.895 11.998047 2 12.893047 2 13.998047 L 2 15.998047 C 2 17.103047 2.895 17.998047 4 17.998047 L 17 17.998047 L 17 22.070312 C 17 22.453312 17.217547 22.80175 17.560547 22.96875 C 17.700547 23.03775 17.851 23.070312 18 23.070312 C 18.219 23.070313 18.436234 23.000328 18.615234 22.861328 L 27.685547 15.789062 C 27.928547 15.599063 28.070312 15.310953 28.070312 15.001953 C 28.070312 14.692953 27.928547 14.401891 27.685547 14.212891 L 18.615234 7.140625 C 18.464234 7.023125 18.286141 6.9546719 18.103516 6.9355469 z" /></svg> */}
                    </Link>
                </div>
            </div>
        </Layout>
    )
}
